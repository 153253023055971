import React, { FC, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from 'src/application/store';
import { services } from './infrastructure/services';

interface IRootProps {
  element: ReactNode
}

const Root: FC<IRootProps> = ({ element }) => (
  <Provider store={configureStore(services)}>{element}</Provider>
);

export default Root;
