import {
  createStore,
  applyMiddleware,
  Store as ReduxStore,
  Reducer,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import dynamicMiddlewares, { addMiddleware } from 'redux-dynamic-middlewares';
import { Services } from './interfaces';
import {
  AppState,
} from './state';
import { createCoreReducers } from './core/reducer';
import {
  createLazyReducers,
  LazyReducer,
  LazyReducerMap,
} from './lazy/reducer';
import { createCoreMiddleware } from './core/middleware';
import { LazyMiddleware } from './lazy/middleware';

export type ExtendedReduxStore<S> = ReduxStore<S> & {
  lazyReducers: LazyReducerMap,
  injectReducer: (
    key: string,
    lazyReducer: LazyReducer,
  ) => void,
  injectMiddleware: (
    lazyMiddleware: LazyMiddleware[],
  ) => void,
};

export const store: { instance: ExtendedReduxStore<AppState> } = {
  instance: {} as ExtendedReduxStore<AppState>,
};

export const configureStore = (
  services: Services,
): ExtendedReduxStore<AppState> => {
  store.instance = createStore(
    createCoreReducers() as Reducer<AppState>,
    composeWithDevTools(
      applyMiddleware(
        ...createCoreMiddleware().map((f) => f(services)),
        dynamicMiddlewares,
      ),
    ),
  ) as ExtendedReduxStore<AppState>;

  store.instance.lazyReducers = {};

  store.instance.injectReducer = (
    key: string,
    lazyReducer: LazyReducer,
  ): void => {
    store.instance.lazyReducers[key] = lazyReducer;

    store.instance.replaceReducer(
      createLazyReducers(
        store.instance.lazyReducers as LazyReducerMap,
      ) as Reducer<AppState>,
    );
  };

  store.instance.injectMiddleware = (
    lazyMiddleware: LazyMiddleware[],
  ) => {
    addMiddleware(...lazyMiddleware.map((f) => f(services)));
  };

  return store.instance;
};
