import { Middleware } from 'redux';
import { Services } from 'application/interfaces';
import { navigate } from 'gatsby';
import { AppError } from 'src/application/entities/app-error';
import * as actions from './actions';

export const TrackShipmentClick = ({
  analytics,
}: Services): Middleware => () => (next) => (action): void => {
  next(action);
  if (action.type === actions.HEADER_TRACK_SHIPMENT_CLICK) {
    /** Core app logic here */
    const locale = action.payload;

    const targetUrl = `${ locale === 'ar' ? '/ar' : '' }/track/`;
    const trackText = locale === 'ar' ? 'تتبّع' : 'Track';

    if (analytics) {
      const event = {
        CurrentURL: window.location?.pathname || 'unavailable',
        LinkURL: targetUrl,
        Section: 'Header',
        Text: trackText,
        event: 'linkClickEvent',
      };

      analytics.pushEvent(event);
    }

    navigate(targetUrl);
  }
};

export const GetInTouchClick = ({
  logger,
}: Services): Middleware => () => (next) => (action): void => {
  next(action);
  if (action.type === actions.HEADER_GET_IN_TOUCH_CLICK) {
    /** Core app logic here */

    logger.info('Get In Touch clicked...');
  }
};

export const OnHeaderMounted = ({
  api,
  logger,
}: Services): Middleware => ({
  dispatch,
}) => (next) => async (action): Promise<void> => {
  next(action);
  if (action.type === actions.HEADER_MOUNTED) {
    if (!api?.other?.getGeolocation) {
      throw new AppError('internalError',
        'Failed accessing geolocation service');
    }

    try {
      const geolocation = await api?.other?.getGeolocation();

      dispatch(actions.setGeolocation(geolocation));
    } catch (e) {
      // Soft error, no need to crash app
      logger.info('Failed getting geolocation');
    }
  }
};

export default [
  TrackShipmentClick,
  GetInTouchClick,
  OnHeaderMounted,
];
