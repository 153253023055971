import axios from 'axios';
import { AppError } from 'src/application/entities/app-error';
import {
  ShipaDeliveryCRMCheckAccount,
  ShipaDeliveryCRMCheckAccountInfo,
  ShipaDeliveryCRMCreateLeadInput,
} from 'src/application/interfaces/models/shipa-delivery/crm';

const crm = {
  createLead: async ({
    firstName,
    lastName,
    companyName,
    phoneNumber,
    email,
    receiveEmails,
    industry,
    dailyVolume,
    marketingCampaign,
  }: ShipaDeliveryCRMCreateLeadInput): Promise<void> => {
    const data = {
      action: 'createlead',
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      country_code: `(${ phoneNumber.countryCode })`,
      phone_number: phoneNumber.number,
      email_address: email,
      is_individual: 'F',
      receive_emails: receiveEmails ? 'T' : 'F',
      business_sector: industry,
      monthly_expected_deliveries: dailyVolume,
      marketing_campaign: marketingCampaign,
    };

    const { GATSBY_SHIPA_API_BASE } = process.env;

    if (!GATSBY_SHIPA_API_BASE) {
      throw new AppError(
        'internalError',
        'GATSBY_SHIPA_API_BASE env variable is empty',
      );
    }

    try {
      const res = await axios.post(
        `${ GATSBY_SHIPA_API_BASE }/sdcrm/createlead`,
        data,
      ) as {
        data: {
          status: string;
          error: string;
          leadid: string;
        }
      };

      if (res.data.status === 'failed') {
        if (res.data.error.includes('INVALID_FLD_VALUE')) {
          throw new AppError('validationError', res.data.error);
        }
        // TODO: Catch AppError in error boundary
        throw new AppError('serverError', res.data.error);
      }
    } catch (error) {
      if (
        error?.response?.data
        && error.response.data[0].code === 400
      ) {
        throw new AppError(
          'validationError',
          error.response.data[0].message,
        );
      } else {
        throw new AppError(
          'internalError',
          'Internal server error. Please contact administrator',
        );
      }
    }
  },
  checkAccount: async ({
    accountNumber,
    // eslint-disable-next-line max-len
  }: ShipaDeliveryCRMCheckAccount): Promise<ShipaDeliveryCRMCheckAccountInfo> => {
    const { GATSBY_SHIPA_API_BASE } = process.env;

    if (!GATSBY_SHIPA_API_BASE) {
      throw new AppError(
        'internalError',
        'GATSBY_SHIPA_API_BASE env variable is empty',
      );
    }

    const data = {
      action: 'checkIsBusiness',
      data: {
        cstNo: accountNumber,
      },
    };

    try {
      const res = await axios.post(
        `${ GATSBY_SHIPA_API_BASE }/sdcs/callback`,
        data,
      ) as {
        status: number;
        data: {
          status: string;
          error: string;
          data: {
            isBusiness: string;
            name: string;
            phone: string;
            email: string;
            cstNo: string;
          }
        }
      };

      if (res.data.status === 'failed') {
        if (res.data.error === 'wrong cstNo') {
          throw new AppError('validationError', 'wrongAccountNumber');
        } else {
          throw new AppError('serverError', res.data.error);
        }
      }
      if (res.data.data.isBusiness === 'F') {
        throw new AppError(
          'validationError',
          'notBusinessAccountError', // translation label
        );
      }

      return {
        accountNumber: res.data.data.cstNo,
        name: res.data.data.name,
        email: res.data.data.email,
        phone: res.data.data.phone,
      };
    } catch (error) {
      if (
          error?.response?.data
          && error.response.data[0].code === 400
      ) {
        throw new AppError(
          'validationError',
          error.response.data[0].message === 'wrong cstNo'
            ? 'wrongAccountNumber'
            : error.response.data[0].message,
        );
      } else {
        throw new AppError(
          'internalError',
          'Internal server error. Please contact administrator',
        );
      }
    }
  },
};

export default crm;
