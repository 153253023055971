export const OPEN_LIVE_CHAT = '[livechat] open click';
export type OnOpenChatClick = {
  type: typeof OPEN_LIVE_CHAT;
}
export const onOpenChatClick = {
  type: OPEN_LIVE_CHAT,
};

export const HIDE_LIVE_CHAT = '[livechat] hide';
export type HideLiveChat = {
  type: typeof HIDE_LIVE_CHAT;
}
export const hideLiveChat = {
  type: HIDE_LIVE_CHAT,
};

export type LiveChatAction =
  OnOpenChatClick
  | HideLiveChat;
