import { Middleware } from 'redux';
import camelCase from 'lodash.camelcase';
import { store } from '../../store';
import { LazyReducer } from '../../lazy/reducer';
import { LazyMiddleware } from '../../lazy/middleware';
import { LOAD_COMPONENT, loadComponentSuccess } from './actions';

type ReducerImport = {
  default: LazyReducer,
};
type MiddlewaresImport = {
  default: LazyMiddleware[],
};

export const componentLoaded = (
): Middleware => ({ dispatch }) => (next) => async (action) => {
  if (action.type === LOAD_COMPONENT) {
    const componentName = action.payload;
    const camelCasedName = camelCase(componentName);

    const alreadyInjected = store.instance.lazyReducers[camelCasedName];
    if (!alreadyInjected) {
      try {
        const reducer = await import(
          `src/application/lazy/${ componentName }/reducer` /* webpackChunkName: "[request]" */ // eslint-disable-line
        ) as ReducerImport;

        store.instance.injectReducer(camelCasedName, reducer.default);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Did not load reducer for ${ componentName }`);
      }
      try {
        const middlewares = await import(
          `src/application/lazy/${ componentName }/middleware` /* webpackChunkName: "[request]" */ // eslint-disable-line
        ) as MiddlewaresImport;

        store.instance.injectMiddleware(middlewares.default);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Did not load middleware for ${ componentName }`);
      }
    }

    // We still want to fire loadComponentSuccess because
    // other middlewares depend on it to know when the module
    // is ready
    dispatch(loadComponentSuccess(componentName));
  }
  next(action);
};

export default [
  componentLoaded,
];
