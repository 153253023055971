// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-views-pages-404-tsx": () => import("./../../../src/views/pages/404.tsx" /* webpackChunkName: "component---src-views-pages-404-tsx" */),
  "component---src-views-templates-detail-page-blog-english-ts": () => import("./../../../src/views/templates/detail-page/blog/english.ts" /* webpackChunkName: "component---src-views-templates-detail-page-blog-english-ts" */),
  "component---src-views-templates-detail-page-blog-translation-ts": () => import("./../../../src/views/templates/detail-page/blog/translation.ts" /* webpackChunkName: "component---src-views-templates-detail-page-blog-translation-ts" */),
  "component---src-views-templates-detail-page-news-english-ts": () => import("./../../../src/views/templates/detail-page/news/english.ts" /* webpackChunkName: "component---src-views-templates-detail-page-news-english-ts" */),
  "component---src-views-templates-detail-page-news-translation-ts": () => import("./../../../src/views/templates/detail-page/news/translation.ts" /* webpackChunkName: "component---src-views-templates-detail-page-news-translation-ts" */),
  "component---src-views-templates-listing-page-blog-english-tsx": () => import("./../../../src/views/templates/listing-page/blog/english.tsx" /* webpackChunkName: "component---src-views-templates-listing-page-blog-english-tsx" */),
  "component---src-views-templates-listing-page-blog-translation-tsx": () => import("./../../../src/views/templates/listing-page/blog/translation.tsx" /* webpackChunkName: "component---src-views-templates-listing-page-blog-translation-tsx" */),
  "component---src-views-templates-listing-page-news-english-tsx": () => import("./../../../src/views/templates/listing-page/news/english.tsx" /* webpackChunkName: "component---src-views-templates-listing-page-news-english-tsx" */),
  "component---src-views-templates-listing-page-news-translation-tsx": () => import("./../../../src/views/templates/listing-page/news/translation.tsx" /* webpackChunkName: "component---src-views-templates-listing-page-news-translation-tsx" */),
  "component---src-views-templates-strapi-page-english-tsx": () => import("./../../../src/views/templates/strapi-page/english.tsx" /* webpackChunkName: "component---src-views-templates-strapi-page-english-tsx" */),
  "component---src-views-templates-strapi-page-translation-tsx": () => import("./../../../src/views/templates/strapi-page/translation.tsx" /* webpackChunkName: "component---src-views-templates-strapi-page-translation-tsx" */)
}

