import axios from 'axios';
import { AppError } from 'src/application/entities/app-error';
import {
  ShipaDeliveryCRMRequestCallback,
} from 'src/application/interfaces/models/shipa-delivery/customer-service';

const cs = {
  requestCallBack: async ({
    isBusiness,
    name,
    phone,
    email,
    accountNumber,
    orderNumbers,
    reason,
    marketingCampaign,
  }: ShipaDeliveryCRMRequestCallback): Promise<void> => {
    const { GATSBY_SHIPA_API_BASE } = process.env;

    if (!GATSBY_SHIPA_API_BASE) {
      throw new AppError(
        'internalError',
        'GATSBY_SHIPA_API_BASE env variable is empty',
      );
    }

    const data = {
      action: 'requestCallBack',
      data: {
        ...{
          isBusiness: isBusiness ? 'T' : 'F',
          name,
          phone,
          marketing_campaign: marketingCampaign,
        },
        ...(email ? {
          email,
        } : {}),
        ...(accountNumber ? {
          cstNo: accountNumber,
        } : {}),
        ...(reason ? {
          reason,
        } : {}),
        ...(orderNumbers ? {
          ordNo: orderNumbers,
        } : {}),
      },
    };

    try {
      const res = await axios.post(
        `${ GATSBY_SHIPA_API_BASE }/sdcs/callback`,
        data,
      ) as {
        status: number;
        data: {
          status: string;
          error: string;
        }
      };

      if (res.data.status !== 'success') {
        // TODO: Catch AppError in error boundary
        throw new AppError('serverError', res.data.error);
      }
    } catch (error) {
      if (
        error?.response?.data
        && error.response.data[0].code === 400
      ) {
        throw new AppError(
          'validationError',
          error.response.data[0].message,
        );
      } else {
        throw new AppError(
          'internalError',
          'Internal server error. Please contact administrator',
        );
      }
    }
  },
};

export default cs;
