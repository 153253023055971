import * as actions from './actions';
import { GeolocationType } from './entities';

export type HeaderState = {
  menuOpen: boolean;
  headerSubmenu: string | null;
  mobileSubmenuId: string | null;
  getInTouchId: string | null;
  geolocation: null | GeolocationType;
};

const initialState: HeaderState = {
  menuOpen: false,
  headerSubmenu: null,
  mobileSubmenuId: null,
  // for other components to open get in touch submenu:
  getInTouchId: null,
  geolocation: null,
};

const headerReducer = (
  state: HeaderState = initialState,
  action: actions.HeaderAction,
): HeaderState => {
  switch (action.type) {
    case actions.HEADER_MENU_TOGGLE:
      return {
        ...state,
        menuOpen: (action as actions.MenuToggle).payload,
      };
    case actions.HEADER_SET_HEADER_SUBMENU:
      return {
        ...state,
        headerSubmenu: (action as actions.SetHeaderSubmenu).payload,
      };
    case actions.HEADER_SET_GET_IN_TOUCH_ID:
      return {
        ...state,
        getInTouchId: (action as actions.SetGetInTouchId).payload,
      };
    case actions.HEADER_SET_MOBILE_SUBMENU:
      return {
        ...state,
        mobileSubmenuId: (action as actions.SetMobileSubmenu).payload,
      };
    case actions.HEADER_SET_GEOLOCATION:
      return {
        ...state,
        geolocation: (action as actions.SetGeolocation).payload,
      };
    default:
      return state;
  }
};

export default headerReducer;
