import axios from 'axios';
import {
  ShipaEcommerceCRMCreateLeadInput,
} from 'application/interfaces/models/shipa-ecommerce/crm';
import { AppError } from 'src/application/entities/app-error';

const shipaEcommerce = {
  CRM: {
    createLead: async ({
      firstName,
      lastName,
      phoneNumber,
      email,
      companyName,
      websiteLink,
      country,
      city,
      ordersPerMonth,
      hasTradeLicense,
      kindOfCommodities,
      additionalDetails,
      agreeToTermsAndConditions,
      campaign,
    }: ShipaEcommerceCRMCreateLeadInput): Promise<void> => {
      const { GATSBY_SHIPA_API_BASE } = process.env;

      if (!GATSBY_SHIPA_API_BASE) {
        throw new AppError(
          'internalError',
          'GATSBY_SHIPA_API_BASE env variable is empty',
        );
      }

      const data = {
        Firstname: firstName,
        Lastname: lastName,
        // crm does not accept special chars
        Phone: phoneNumber.countryCode.replace('+', '') + phoneNumber.number,
        Email: email,
        Company: companyName,
        // crm does not accept emojis (flags)
        // eslint-disable-next-line max-len
        Country: country.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''),
        City: city,
        CW_Do_you_have_a_trade_license__c: hasTradeLicense,
        Average_Order_per_Month__c: ordersPerMonth,
        CW_Comments__c: additionalDetails,
        Consent__c: agreeToTermsAndConditions.agreed,
        Website: websiteLink || '',
        Campaign_Name__c: campaign?.name || '',
        Campaign_Source__c: campaign?.source || '',
        Campaign_Medium__c: campaign?.medium || '',
        Commodities__c: kindOfCommodities.join(';'),
        What_services_do_you_need_help_with__c: 'Crossborder shipping',
      };

      try {
        const res = await axios.post(
          `${ GATSBY_SHIPA_API_BASE }/seccrm/createlead`,
          data,
        ) as {
          status: number;
          data: {
            id: string;
            success: boolean;
            errors: unknown[];
          }
        };

        if (!res.data.success) {
          throw new AppError('serverError', JSON.stringify(res.data.errors));
        }
      } catch (error) {
        if (
          error?.response?.data
          && error.response.data[0].code === 400
        ) {
          throw new AppError(
            'validationError',
            error.response.data[0].message,
          );
        } else {
          throw new AppError(
            'internalError',
            'Internal server error. Please contact administrator',
          );
        }
      }
    },
  },
};

export default shipaEcommerce;
