import { AppMiddleware } from '../middleware';
import control from './control/middleware';
import header from './header/middleware';
import analytics from './analytics/middleware';
import liveChat from './live-chat/middleware';

export const createCoreMiddleware = (): AppMiddleware[] => ([
  ...control,
  ...header,
  ...analytics,
  ...liveChat,
]);
