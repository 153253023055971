import axios from 'axios';
import { AppError } from 'src/application/entities/app-error';
import { GeolocationOutput } from 'src/application/interfaces/models/others';

type ApiResponse = {
  ip: string;
  city: string;
  country: string;
  region: string;
}

export const getGeolocation = async (): Promise<GeolocationOutput> => {
  try {
    const res = await axios.get(
      'https://ipinfo.io?token=cf9047241656a3',
    ) as {
      data: ApiResponse
    };

    const {
      country: countryCode,
      city,
    } = res.data;

    return { countryCode, city };
  } catch (e) {
    if (e.response && e.response.status === 429) {
      throw new AppError('internalError', 'Rate limit exceeded');
    }
    throw new AppError('internalError', 'Something went wrong');
  }
};
