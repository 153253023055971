import { combineReducers, Reducer } from 'redux';
import { initCoreReducers } from '../core/reducer';
import { AppState } from '../state';
/**
 * !!! Do not import JS code, only TypeScript
 */
import { InfographicsState } from './infographics/reducer';
import { TimelineWithImagesState } from './timeline-with-images/reducer';
import { TrackingState } from './tracking/reducer';
import { HeroState } from './hero/reducer';
import { SubscribeState } from './subscribe/reducer';
import { JoinDeliveryFleetData } from './join-delivery-fleet/reducer';
import { SdBusinessCallbackData } from './sd-business-callback/reducer';
import { SdConsumerCallbackData } from './sd-consumer-callback/reducer';
import { SecCallbackData } from './sec-callback/reducer';
import { SdWorkWithUsData } from './sd-work-with-us/reducer';
import { TrackShipmentData } from './track-shipment/reducer';
import { SubmitResumeData } from './submit-resume/reducer';
import { SecGetInTouchData } from './sec-get-in-touch/reducer';
import { JobListingState } from './job-listing/reducer';

export type LazyState = {
  timelineWithImages?: TimelineWithImagesState;
  infographics?: InfographicsState;
  tracking?: TrackingState;
  hero?: HeroState;
  subscribe?: SubscribeState;
  joinDeliveryFleet?: JoinDeliveryFleetData;
  sdBusinessCallback?: SdBusinessCallbackData;
  sdConsumerCallback?: SdConsumerCallbackData;
  secCallback?: SecCallbackData
  trackShipment?: TrackShipmentData
  sdWorkWithUs?: SdWorkWithUsData;
  submitResume?: SubmitResumeData;
  secGetInTouch?: SecGetInTouchData;
  jobListing?: JobListingState;
}

export type LazyReducer = any; // eslint-disable-line

export type LazyReducerMap = {
  [key: string]: LazyReducer,
};

export const createLazyReducers = (
  lazyReducers: LazyReducerMap,
): Reducer<AppState> => combineReducers({
  ...initCoreReducers(),
  ...lazyReducers,
});
