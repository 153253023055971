import {
  combineReducers,
  Reducer,
  ReducersMapObject,
} from 'redux';
import { AppState } from '../state';
import header, { HeaderState } from './header/reducer';

export type CoreState = {
  header: HeaderState,
};

export const initCoreReducers = (
): ReducersMapObject<CoreState> => ({
  header,
});

export const createCoreReducers = (
): Reducer<AppState> => combineReducers<AppState>({
  ...initCoreReducers(),
});
