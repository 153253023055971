import { GeolocationType } from './entities';

export const HEADER_MOUNTED = '[header] mounted';
export const headerMounted = {
  type: HEADER_MOUNTED,
};

export const HEADER_MENU_TOGGLE = '[header] menu toggle';
export type MenuToggle = {
  type: typeof HEADER_MENU_TOGGLE;
  payload: boolean;
};
export const menuToggle = (isOpen: boolean): MenuToggle => ({
  type: HEADER_MENU_TOGGLE,
  payload: isOpen,
});

export const HEADER_SET_HEADER_SUBMENU = '[header] header submenu toggle';
export type SetHeaderSubmenu = {
  type: typeof HEADER_SET_HEADER_SUBMENU;
  payload: string | null;
};
export const setHeaderSubmenu = (id: string | null): SetHeaderSubmenu => ({
  type: HEADER_SET_HEADER_SUBMENU,
  payload: id,
});

export const HEADER_SET_MOBILE_SUBMENU = '[header] set mobile submenu';
export type SetMobileSubmenu = {
  type: typeof HEADER_SET_MOBILE_SUBMENU;
  payload: string | null;
};
export const setMobileSubmenu = (id: string | null): SetMobileSubmenu => ({
  type: HEADER_SET_MOBILE_SUBMENU,
  payload: id,
});

export const HEADER_SET_GET_IN_TOUCH_ID = '[header] set get in touch id';
export type SetGetInTouchId = {
  type: typeof HEADER_SET_GET_IN_TOUCH_ID;
  payload: string | null;
};
export const setGetInTouchId = (id: string | null): SetGetInTouchId => ({
  type: HEADER_SET_GET_IN_TOUCH_ID,
  payload: id,
});

export const HEADER_GET_IN_TOUCH_CLICK = '[header] get in touch click';
export type OnGetInTouchClick = {
  type: typeof HEADER_GET_IN_TOUCH_CLICK,
};
export const onGetInTouchClick = {
  type: HEADER_GET_IN_TOUCH_CLICK,
};

export const HEADER_TRACK_SHIPMENT_CLICK = '[header] track shipment click';
export type TrackShipmentClick = {
  type: typeof HEADER_TRACK_SHIPMENT_CLICK;
  payload: string;
};
export const onTrackShipmentClick = (locale: string): TrackShipmentClick => ({
  type: HEADER_TRACK_SHIPMENT_CLICK,
  payload: locale,
});

export const HEADER_SET_GEOLOCATION = '[header] geolocation/set';
export type SetGeolocation = {
  type: typeof HEADER_SET_GEOLOCATION;
  payload: GeolocationType;
};
export const setGeolocation = (
  geolocation: GeolocationType,
): SetGeolocation => ({
  type: HEADER_SET_GEOLOCATION,
  payload: geolocation,
});

export type HeaderAction =
  MenuToggle
  | SetHeaderSubmenu
  | SetMobileSubmenu
  | OnGetInTouchClick
  | SetGetInTouchId
  | SetGeolocation;
