// prefer default export if available

const preferDefault = m => m && m.default || m
exports.components = {
  "component---src-views-components-hero-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/8cd5bacb-6533-5e4e-9537-d73e9763f9de.js" /* webpackChunkName: "component---src-views-components-hero-index-tsx" */).then(preferDefault),
  "component---src-views-components-infographics-with-phases-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/bfd849ee-6561-5dac-9eac-0f53d8373876.js" /* webpackChunkName: "component---src-views-components-infographics-with-phases-index-tsx" */).then(preferDefault),
  "component---src-views-components-features-list-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/de489bb5-d271-5b92-96ce-9815e7db43ca.js" /* webpackChunkName: "component---src-views-components-features-list-index-tsx" */).then(preferDefault),
  "component---src-views-components-card-slider-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/547dba0b-28a4-56a8-aad1-fec734180dae.js" /* webpackChunkName: "component---src-views-components-card-slider-index-tsx" */).then(preferDefault),
  "component---src-views-components-simple-card-list-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/38d4684d-e786-5c81-bb9f-26d36ecc7780.js" /* webpackChunkName: "component---src-views-components-simple-card-list-index-tsx" */).then(preferDefault),
  "component---src-views-components-toggable-content-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/221112c8-694b-5920-938b-9bc31fa2b9b9.js" /* webpackChunkName: "component---src-views-components-toggable-content-index-tsx" */).then(preferDefault),
  "component---src-views-components-textual-content-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/a4e7096a-69af-561d-81e5-8b9e20b1cf28.js" /* webpackChunkName: "component---src-views-components-textual-content-index-tsx" */).then(preferDefault),
  "component---src-views-components-icon-menu-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/de365b42-c3d1-5915-a5a3-0275642d2a4a.js" /* webpackChunkName: "component---src-views-components-icon-menu-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/2299d2a5-20f7-5da8-a24b-175b88a7acc0.js" /* webpackChunkName: "component---src-views-components-media-index-tsx" */).then(preferDefault),
  "component---src-views-components-icon-box-grid-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/2375d2e6-d1c1-5a3d-aee1-8d7b13b8a94f.js" /* webpackChunkName: "component---src-views-components-icon-box-grid-index-tsx" */).then(preferDefault),
  "component---src-views-components-half-content-half-widget-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/5aed7e0c-4f13-50da-98ed-d0e37cb4d9e3.js" /* webpackChunkName: "component---src-views-components-half-content-half-widget-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-text-box-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/d9c4286d-9490-5654-9d97-e41d0a462f2e.js" /* webpackChunkName: "component---src-views-components-media-text-box-index-tsx" */).then(preferDefault),
  "component---src-views-components-timeline-with-images-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/63305166-780d-54f8-a5df-e7d0449ca0d5.js" /* webpackChunkName: "component---src-views-components-timeline-with-images-index-tsx" */).then(preferDefault),
  "component---src-views-components-columns-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/42294edd-ab95-535e-9d1a-c9fd5a9c6c65.js" /* webpackChunkName: "component---src-views-components-columns-index-tsx" */).then(preferDefault),
  "component---src-views-components-our-team-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/d724603f-45cb-58fc-a424-72a4f5b60407.js" /* webpackChunkName: "component---src-views-components-our-team-index-tsx" */).then(preferDefault),
  "component---src-views-components-vertical-scroll-slides-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/2478b8d7-8afb-5118-89c3-63a96d32ca46.js" /* webpackChunkName: "component---src-views-components-vertical-scroll-slides-index-tsx" */).then(preferDefault),
  "component---src-views-components-faq-section-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/65acfbf8-ea89-5407-9b6a-09fc8ca474c8.js" /* webpackChunkName: "component---src-views-components-faq-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-faq-list-section-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/6b6a34f6-cf51-51b0-b17e-003de180fb5c.js" /* webpackChunkName: "component---src-views-components-faq-list-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-form-with-media-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/1faa99a1-6212-5952-9690-10720bc30f5c.js" /* webpackChunkName: "component---src-views-components-form-with-media-index-tsx" */).then(preferDefault),
  "component---src-views-components-video-section-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/4c3d5853-4565-57d7-b913-263f59475560.js" /* webpackChunkName: "component---src-views-components-video-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-job-listing-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/8083861a-1d35-5d4c-8745-7946f588d7c7.js" /* webpackChunkName: "component---src-views-components-job-listing-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-slider-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/99aa55bf-e255-5891-bb04-126b75b70645.js" /* webpackChunkName: "component---src-views-components-media-slider-index-tsx" */).then(preferDefault),
  "component---src-views-components-help-center-flow-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/c007accf-b967-5c07-b7aa-1b16adc1c598.js" /* webpackChunkName: "component---src-views-components-help-center-flow-index-tsx" */).then(preferDefault),
  "component---src-views-components-track-shipment-results-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/24cea338-67fc-58a3-9591-7ed377babdbe.js" /* webpackChunkName: "component---src-views-components-track-shipment-results-index-tsx" */).then(preferDefault),
  "component---src-views-components-faqs-contact-details-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/6dd6aac5-c0b1-5f35-bef3-ddbcf3d7ce23.js" /* webpackChunkName: "component---src-views-components-faqs-contact-details-index-tsx" */).then(preferDefault),
  "component---src-views-components-sitemap-index-tsx": () => import("/codebuild/output/src562390747/src/.cache/gatsby-plugin-graphql-component/components/1782d58e-3b87-57b6-adb0-befbba5f53e0.js" /* webpackChunkName: "component---src-views-components-sitemap-index-tsx" */).then(preferDefault)
}

