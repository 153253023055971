import { Api } from 'application/interfaces/api';
import crm from './crm';
import cs from './cs';

const shipaDelivery: Api['shipaDelivery'] = {
  CRM: crm,
  CS: cs,
};

export default shipaDelivery;
