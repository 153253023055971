import axios from 'axios';

import { AppError } from 'src/application/entities/app-error';
import {
  JobListOutput,
  WorkableJobListOutput,
} from 'src/application/interfaces/models/workable';

import { convertWorkableJobItem } from './service';

const workable = {
  getJobList: async (): Promise<JobListOutput> => {
    const { GATSBY_SHIPA_API_BASE } = process.env;

    if (!GATSBY_SHIPA_API_BASE) {
      throw new AppError(
        'internalError',
        'GATSBY_SHIPA_API_BASE env variable is empty',
      );
    }

    try {
      const res = await axios.get<WorkableJobListOutput>(
        `${ GATSBY_SHIPA_API_BASE }/workable-jobs`,
        {
          params: {
            limit: 200,
          },
        },
      );

      return {
        ...res?.data,
        jobs:
          res?.data?.jobs?.map(
            (workableJob) => convertWorkableJobItem(workableJob)
          ) || [],
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      if (error.response?.data) {
        if (error.response.status === 400 || error.response.status === 404) {
          throw new AppError('validationError', error.response.data[0].message);
        } else {
          throw new AppError(
            'serverError',
            'Server error. Please contact administrator.',
          );
        }
      }

      throw new AppError('internalError', error.message);
    }
  },
};

export default workable;
