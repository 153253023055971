/* eslint-disable no-param-reassign */
import { visitor } from './visitor';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const transform = ({ json = {}, load }) => {
  const promises = [];

  const newJson = visitor({
    json,
    onDefinition: ({ definition, value, key }) => {
      promises.push(
        load(definition).then((component) => {
          value[key] = component;
        }),
      );
    },
  });

  return Promise.all(promises).then(() => newJson);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const transformSync = ({ json = {}, load }) => visitor({
  json,
  onDefinition: ({ definition, value, key }) => {
    value[key] = load(definition);
  },
});
