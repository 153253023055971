/* eslint-disable @typescript-eslint/no-var-requires, global-require */
import { components } from '~gatsby-plugin-graphql-component/async-requires'; // eslint-disable-line import/no-unresolved, max-len
import { transform } from './transform';

// on client entry is called almost as a first lifecycle
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onClientEntry = async () => {
  // this code patches "development" mode
  if (process.env.NODE_ENV !== 'production') {
    // require is used so the
    // requires can be be removed during production build
    const { default: socketIo } = require('~gatsby-plugin-graphql-component-gatsby-cache/socketIo'); // eslint-disable-line import/no-unresolved, max-len
    const syncRequires = require('~gatsby-plugin-graphql-component/sync-requires'); // eslint-disable-line import/no-unresolved, max-len
    const { transformSync } = require('./transform');

    const emitter = window.___emitter; // eslint-disable-line no-underscore-dangle, max-len

    const onResult = ({ result }) => {
      if (result) {
        Object.assign(
          result,
          transformSync({
            json: result,
            load: ({
              componentChunkName,
            }) => syncRequires.components[componentChunkName],
          }),
        );
      }
    };

    // emmitter emits new results on page / static query queries,
    // we will patch the response with components
    emitter.on('staticQueryResult', onResult);
    emitter.on('pageQueryResult', onResult);

    // we need to initialize socketIo, because core initializes it
    // after this call,
    // but it needs to be initilized when calling loadPage later in the code
    socketIo();
  }

  const loader = window.___loader; // eslint-disable-line no-underscore-dangle

  const { loadPage } = loader;

  const cache = new WeakMap();

  // patch query json result in loadPage
  loader.loadPage = async (...args) => {
    const result = await loadPage(...args);

    if (cache.has(result)) {
      return result;
    }

    if (result && result.json && result.json.data) {
      result.json.data = await transform({
        json: result.json.data,
        load: ({ componentChunkName }) => components[componentChunkName](),
      });

      cache.set(result, true);
    }

    return result;
  };

  // call loader public method before gatsby core calls underlying
  // private method later after this lifecycle
  // therefore our patched method would not be called
  // network calls are reused in loader internally, so the page won't be
  // loaded twice
  // all other code in core uses this public method, so we should be safe
  return loader.loadPage(window.location.pathname);
};
