export const LOAD_COMPONENT = '[control] component/load';

export type LoadComponentAction = {
  type: typeof LOAD_COMPONENT,
  payload: string,
};
export const loadComponent = (componentName: string): LoadComponentAction => ({
  type: LOAD_COMPONENT,
  payload: componentName,
});

export const LOAD_COMPONENT_SUCCESS = '[control] component/load-success';

export type LoadComponentSuccess = {
  type: typeof LOAD_COMPONENT_SUCCESS,
  payload: string,
};
export const loadComponentSuccess = (
  componentName: string,
): LoadComponentSuccess => ({
  type: LOAD_COMPONENT_SUCCESS,
  payload: componentName,
});

export const CONTROL_APP_LOADED = '[control] app/load';
export const appLoaded = {
  type: CONTROL_APP_LOADED,
};

export type ControlAction =
  LoadComponentAction;
