import { navigate } from '@reach/router';

const queryStringParameters = {
  update: (key: string, value: string): void => {
    const paramsString = window.location.search;
    const searchParams = new URLSearchParams(paramsString);
    searchParams.set(key, value);
    navigate(`?${ searchParams.toString() }`);
  },
};

export default queryStringParameters;
