/* eslint-disable camelcase */
import {
  JobItem,
  WorkableJobItem,
} from 'application/interfaces/models/workable';

export const convertWorkableJobItem = (
  workableJobItem: WorkableJobItem,
): JobItem => ({
  id: workableJobItem.id,
  title: workableJobItem.title,
  department: workableJobItem.department,
  url: workableJobItem.url,
  state: workableJobItem.state,
  location: {
    locationStr: workableJobItem.location?.location_str,
    country: workableJobItem.location?.country,
    countryCode: workableJobItem.location?.country_code,
    region: workableJobItem.location?.region,
    regionCode: workableJobItem.location?.region_code,
    city: workableJobItem.location?.city,
    zipCode: workableJobItem.location?.zip_code,
  },
  createdAt: workableJobItem.created_at,
});
