import { Middleware } from 'redux';
import { Services } from 'application/interfaces';
import * as actions from './actions';

export const OpenChatClickFlow = ({
  liveChat,
}: Services): Middleware => () => (next) => (action): void => {
  next(action);
  if (action.type === actions.OPEN_LIVE_CHAT) {
    /** Core app logic here */

    if (liveChat?.open) {
      liveChat.open();
    }
  }
};

export const HideLiveChatFlow = ({
  liveChat,
}: Services): Middleware => () => (next) => (action): void => {
  next(action);
  if (action.type === actions.HIDE_LIVE_CHAT) {
    /** Core app logic here */

    if (liveChat?.hide) {
      setTimeout(() => liveChat?.hide && liveChat.hide(), 0);
    }
  }
};

export default [
  OpenChatClickFlow,
  HideLiveChatFlow,
];
