import { Services } from 'src/application/interfaces';
import logger from '../logger';

declare global {
  interface Window {
    dataLayer: {[key: string]: string | number | undefined }[]
  }
}

const analytics: Services['analytics'] = {
  pushEvent: (event) => {
    const exists = typeof window.dataLayer !== 'undefined';

    if (!exists) {
      logger.info('Failed pushing analytics event. dataLayer not found.');
      return;
    }

    window.dataLayer.push(event);
  },
};

export default analytics;
