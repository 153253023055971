import logger from '../logger';

declare global {
  interface Window {
    Intercom: (command: string, options?: {
      hide_default_launcher?: boolean, // eslint-disable-line
    }) => void;
  }
}

export type LiveChatType = {
  open?: () => void;
  hide?: () => void;
};

const liveChat: LiveChatType = {
  open: (): void => {
    const exists = typeof window.Intercom !== 'undefined';

    if (!exists) {
      logger.info('Failed opening Intercom. Object not found.');
      return;
    }

    window.Intercom('show');
  },
  hide: (): void => {
    if (typeof window !== 'undefined') {
      window.Intercom('hide');
      window.Intercom('update', { hide_default_launcher: true });
    }
  },
};

export default liveChat;
