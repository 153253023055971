/**
 * !!! Do not import JS code from LAZY, only TypeScript
 */
import {
  Middleware,
} from 'redux';
import {
  SetFormSuccess as GetInTouchSuccess,
} from 'src/application/lazy/sec-get-in-touch/actions';
import { Services } from 'src/application/interfaces';
import {
  SetFormSuccess,
} from 'src/application/lazy/track-shipment/actions';
import {
  SetFormSuccess as JoinDeliveryFleetSuccess,
} from 'src/application/lazy/join-delivery-fleet/actions';
import {
  SetFormSuccess as SubmitYourResumeSuccess,
} from 'src/application/lazy/submit-resume/actions';
import {
  SetFormSuccess as SdConsumerCallbackSuccess,
} from 'src/application/lazy/sd-consumer-callback/actions';
import {
  SetFormSuccess as SdBusinessCallbackSuccess,
} from 'src/application/lazy/sd-business-callback/actions';
import {
  SetFormSuccess as SdBusinessWorkSuccess,
} from 'src/application/lazy/sd-work-with-us/actions';
import {
  ANALYTICS_HERO_TRACK_SUCCESS,
  TrackHeroTrackSuccess,
} from './actions';

const secGetInTouchFormSubmitted = ({ analytics }: Services): Middleware => (
) => (next) => async (action: GetInTouchSuccess): Promise<void> => {
  next(action);

  if (
    action.type === '[forms] set success sec get in touch'
    && action.payload.isSuccess
  ) {
    if (!analytics) {
      return;
    }

    analytics.pushEvent({
      Category: 'Form',
      Action: 'Success',
      Label: 'Ecommerce - Get in Touch',
      event: 'semEvent',
    });
  }
};

const sdBusinessWorkWithUsSubmitted = ({ analytics }: Services): Middleware => (
) => (next) => async (action: SdBusinessWorkSuccess): Promise<void> => {
  next(action);

  if (
    action.type === '[forms] set success sd business work'
    && action.payload.isSuccess
  ) {
    if (!analytics) {
      return;
    }

    analytics.pushEvent({
      Category: 'Form',
      Action: 'Success',
      Label: 'SD Business - Work with Us',
      event: 'semEvent',
    });
  }
};

const sdBusinessCallbackSubmitted = ({ analytics }: Services): Middleware => (
) => (next) => async (action: SdBusinessCallbackSuccess): Promise<void> => {
  next(action);

  if (
    action.type === '[forms] set success sd business call back'
    && action.payload.isSuccess
  ) {
    if (!analytics) {
      return;
    }

    analytics.pushEvent({
      Category: 'Form',
      Action: 'Success',
      Label: 'SD Business - Call Back',
      event: 'semEvent',
    });
  }
};

const sdConsumerCallbackSubmitted = ({ analytics }: Services): Middleware => (
) => (next) => async (action: SdConsumerCallbackSuccess): Promise<void> => {
  next(action);

  if (
    action.type === '[forms] set success sd consumer call back'
    && action.payload.isSuccess
  ) {
    if (!analytics) {
      return;
    }

    analytics.pushEvent({
      Category: 'Form',
      Action: 'Success',
      Label: 'SD Consumer - Call Back',
      event: 'semEvent',
    });
  }
};

const submitResumeFormSubmitted = ({ analytics }: Services): Middleware => (
) => (next) => async (action: SubmitYourResumeSuccess): Promise<void> => {
  next(action);

  if (
    action.type === '[forms] set success submit resume'
    && action.payload.isSuccess
  ) {
    if (!analytics) {
      return;
    }

    analytics.pushEvent({
      Category: 'Form',
      Action: 'Success',
      Label: 'Careers - Submit Resume',
      event: 'semEvent',
    });
  }
};

const joinDeliveryFleetSubmitted = ({ analytics }: Services): Middleware => (
) => (next) => async (action: JoinDeliveryFleetSuccess): Promise<void> => {
  next(action);

  if (
    action.type === '[forms] set success delivery fleet'
    && action.payload.isSuccess
  ) {
    if (!analytics) {
      return;
    }

    analytics.pushEvent({
      Category: 'Form',
      Action: 'Success',
      Label: 'Careers - Join Our Fleet',
      event: 'semEvent',
    });
  }
};

const trackHeroTrackSuccessFlow = ({ analytics }: Services): Middleware => (
) => (next) => async (action: TrackHeroTrackSuccess): Promise<void> => {
  next(action);

  if (
    action.type === ANALYTICS_HERO_TRACK_SUCCESS
  ) {
    if (!analytics) {
      return;
    }

    analytics.pushEvent({
      Category: 'Form',
      Action: 'Success',
      Label: 'Track',
      event: 'semEvent',
      nonInteraction: 'false',
    });
  }
};

const trackTrackFormSuccessFlow = ({ analytics }: Services): Middleware => (
) => (next) => async (action: SetFormSuccess): Promise<void> => {
  next(action);

  if (
    action.type === '[forms] set success track shipment'
  ) {
    if (!analytics) {
      return;
    }

    analytics.pushEvent({
      Category: 'Form',
      Action: 'Success',
      Label: 'Track',
      event: 'semEvent',
      nonInteraction: 'false',
    });
  }
};

export default [
  secGetInTouchFormSubmitted,
  sdBusinessWorkWithUsSubmitted,
  sdBusinessCallbackSubmitted,
  sdConsumerCallbackSubmitted,
  submitResumeFormSubmitted,
  joinDeliveryFleetSubmitted,
  trackHeroTrackSuccessFlow,
  trackTrackFormSuccessFlow,
];
