import { Services } from 'application/interfaces';

import shipaDelivery from './shipa-delivery';
import shipaEcommerce from './shipa-ecommerce';
import hr from './hr';
import other from './other';
import shipments from './shipments';
import workable from './workable';

const api: Services['api'] = {
  shipaDelivery,
  hr,
  shipaEcommerce,
  other,
  shipments,
  workable,
};

export default api;
