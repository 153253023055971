type ErrorCode = 'internalError' | 'validationError' | 'serverError';

export class AppError extends Error {
  code: ErrorCode;

  description: string;

  constructor(code: ErrorCode, description: string) {
    super(description);
    this.code = code;
    this.description = description;

    // https://stackoverflow.com/q/41102060/8936345
    Object.setPrototypeOf(this, AppError.prototype);
  }
}
