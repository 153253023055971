import axios from 'axios';
import {
  SubmitResumeInput,
  JoinDeliveryFleetInput,
} from 'application/interfaces/models/hr';
import { AppError } from 'src/application/entities/app-error';
import { Api } from 'src/application/interfaces/api';

const hr: Api['hr'] = {
  submitResume: async ({
    firstName,
    lastName,
    currentLocation,
    phoneNumber,
    email,
    resume,
    designation,
    yearsOfExperience,
    role,
    linkedinUrl,
    isPolicyChecked,
  }: SubmitResumeInput): Promise<void> => {
    const { GATSBY_CMS_URL } = process.env;

    if (!GATSBY_CMS_URL) {
      throw new AppError(
        'internalError',
        'GATSBY_CMS_URL env variable is empty',
      );
    }
    const formData = new FormData();

    formData.append('files.resume', resume, resume.name);
    formData.append('data', JSON.stringify({
      ...{
        firstName,
        lastName,
        currentLocation,
        phoneNumber: phoneNumber.countryCode + phoneNumber.number,
        email,
        isPolicyChecked,
      },
      ...(designation ? {
        currentDesignation: designation,
      } : {}),
      ...(yearsOfExperience ? {
        yearsOfExperience,
      } : {}),
      ...(role ? {
        rolesInterestedIn: role,
      } : {}),
      ...(linkedinUrl ? {
        linkedinProfile: linkedinUrl,
      } : {}),
    }));

    try {
      await axios.post(
        `${ GATSBY_CMS_URL }/resume-submissions`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
        },
      );
    } catch (error) {
      if (
        error.response
        && error.response.data
        && error.response.data.message === 'ValidationError') {
        // eslint-disable-next-line
          console.error('VALIDATION ERROR!');
      } else {
        throw new AppError(
          'internalError',
          'Internal server error. Please contact administrator',
        );
      }
    }
  },
  joinDeliveryFleet: async ({
    title,
    firstName,
    lastName,
    currentLocation,
    phoneNumber,
    email,
    resume,
    currentDesignation,
    vehicle,
    isPolicyChecked,
    inviteCode,
  }: JoinDeliveryFleetInput): Promise<void> => {
    const { GATSBY_CMS_URL } = process.env;

    if (!GATSBY_CMS_URL) {
      throw new AppError(
        'internalError',
        'GATSBY_CMS_URL env variable is empty',
      );
    }
    const formData = new FormData();

    formData.append('files.resume', resume, resume.name);
    formData.append('data', JSON.stringify({
      ...{
        firstName,
        lastName,
        currentLocation,
        phoneNumber: phoneNumber.countryCode + phoneNumber.number,
        email,
        vehicle,
        isPolicyChecked,
      },
      ...(title ? {
        title,
      } : {}),
      ...(currentDesignation ? {
        currentDesignation,
      } : {}),
      ...(inviteCode ? {
        inviteCode,
      } : {}),
    }));

    try {
      await axios.post(
        `${ GATSBY_CMS_URL }/resume-submissions/drivers`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
        },
      );
    } catch (error) {
      if (
        error.response
        && error.response.data
        && error.response.data.message === 'ValidationError') {
        // eslint-disable-next-line
          console.error('VALIDATION ERROR!');
      } else {
        throw new AppError(
          'internalError',
          'Internal server error. Please contact administrator',
        );
      }
    }
  },
};

export default hr;
