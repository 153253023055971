import { Services } from 'application/interfaces';
import logger from './logger';
import liveChat from './live-chat';
import api from './api';
import analytics from './analytics';
import queryStringParameters from './query-string-parameters';
import platform from './platform';

/* All platform, infrastructure specific services attached here */
export const services: Services = {
  analytics,
  logger,
  liveChat,
  api,
  queryStringParameters,
  platform,
};
