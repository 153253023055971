import Cookies from 'js-cookie';
import { Services } from '../../../application/interfaces/index';

const platform: Services['platform'] = {
  window: {
    open: (url: string):void => {
      if (typeof window !== 'undefined') {
        window.open(url);
      }
    },
  },
  storage: {
    getCookie: (key: string): undefined | string => Cookies.get(key),
  },
};

export default platform;
