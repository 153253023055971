import axios, { AxiosResponse } from 'axios';
import { AppError } from 'src/application/entities/app-error';
import { TrackingResults } from 'src/application/entities/tracking-results';

const shipments = {
  track: async (
    trackingno: string,
    userId?: string,
  ): Promise<TrackingResults> => {
    const { GATSBY_SHIPA_API_BASE } = process.env;

    if (!GATSBY_SHIPA_API_BASE) {
      throw new AppError(
        'internalError',
        'GATSBY_SHIPA_API_BASE env variable is empty',
      );
    }

    try {
      const userQuery = userId ? `&userid=${
        btoa(encodeURIComponent(userId)) // base64
      }` : '';
      const res = await axios.get(
        `${ GATSBY_SHIPA_API_BASE }/track-shipments?trackingno=${
          trackingno
        }${ userQuery }&source=shipa.com`,
      ) as AxiosResponse<TrackingResults>;

      return res.data;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      if (error.response?.data) {
        if (error.response.status === 400 || error.response.status === 404) {
          throw new AppError(
            'validationError',
            error.response.data[0].message,
          );
        } else {
          throw new AppError(
            'serverError',
            'Server error. Please contact administrator.',
          );
        }
      }
      throw new AppError(
        'internalError',
        error.message,
      );
    }
  },
};

export default shipments;
